import { useEffect } from "react";
import { useAuthContext } from "../../../context-state/AuthContext";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { PATH_DASHBOARD } from "../../../routes/paths";
import { LinearLoader } from "../../../components/linear-loader/LinearLoader";
import { useMarketplaceContext } from "../../../context-state/MarketplaceContext";

// Set chosen sub account before continue to the subAccount paths.
export const SubAccountGuard = () => {

    const { allAccounts, setChosenAccount, account } = useAuthContext();
    const { marketplaceAccountsSellers } =  useMarketplaceContext()
    const navigate = useNavigate()
    const { subId } = useParams()

    useEffect(() => {
        let chosenSubAccount = allAccounts.find(subAccount => subAccount._id === subId)
        if (!chosenSubAccount) {
            // if it's marketplace owner, we search through all the marketplace accounts (sellers)
            chosenSubAccount = marketplaceAccountsSellers.find(subAccount => subAccount._id === subId)
            if (!chosenSubAccount) {
                navigate(PATH_DASHBOARD.sub.root)
                return
            }
        }
        setChosenAccount(chosenSubAccount)
    }, [])

    return account ? <Outlet /> : <LinearLoader />


}
