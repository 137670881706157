import { useMarketplaceContext } from "../../../context-state/MarketplaceContext.jsx";
import { useSnackbar } from "notistack";
import * as API from "../../../api/index.js";
import { LinearLoader } from "../../../components/linear-loader/LinearLoader.jsx";
import { useEffect } from "react";
import { useAuthContext } from "../../../context-state/AuthContext.jsx";
import { useGeneralAppContext } from "../../../context-state/GeneralAppContext.jsx";
// TODO - change name
export const DashboardGuard = ({ children }) => {

    const { getPrimaryAccount, user } =  useAuthContext()
    const { marketplace } =  useMarketplaceContext()
    const { setGeneralAppData, generalAppData } =  useGeneralAppContext()
    const { enqueueSnackbar } = useSnackbar()
    const sellerAccount = getPrimaryAccount()

    const fetchData = async () => {
        try {

            if (!user.isSeller && !user.isMarketPlaceOwner) {
                setGeneralAppData({})
                return
            }

            const date = new Date();
            const thisYearStarts = new Date(date.getFullYear(), 0, 1);
            const nextMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);

            const [
                seasonTicketEarningBySellerResponse,
                totalEarnedByDatesResponse,
                endUsersOfAccountResponse,
                eventOrdersOfAccountResponse,
                eventsOfAccountResponse,
                totalUsersFromThisYearResponse,
                totalEarnedBySellersResponse,
                seasonTicketEarningBySellerGroupByEventResponse,
            ] = await Promise.all([
                API.getTotalSeasonTicketsEarningBySellerId({ marketplaceId: marketplace._id, sellerId: sellerAccount._id, startDate: thisYearStarts.toString(), endDate: nextMonth.toString() }),
                API.getTotalOrdersEarnedBySeller({ marketplaceId: marketplace._id, sellerId: sellerAccount._id }),
                API.getEndUsersOfAccount({ marketplaceId: marketplace._id, accountId: sellerAccount._id, limit: 10 }),
                API.getEventOrdersOfAccount({ accountId: sellerAccount._id }),
                API.getSellerEvents(sellerAccount._id),
                API.aggregateEndUsersBySellerByMonthOrSpecificDates({ marketplaceId: marketplace._id, sellerId: sellerAccount._id, startDate: thisYearStarts.toString(), endDate: nextMonth.toString() }),
                API.getTotalOrdersEarnedBySeller({ marketplaceId: marketplace._id, sellerId: sellerAccount._id, startDate: thisYearStarts.toString(), endDate: nextMonth.toString(), byMarketplace: true }),
                API.getTotalOrdersEarnedBySellerGroupsByEvent({ marketplaceId: marketplace._id, sellerId: sellerAccount._id }),
            ])

            setGeneralAppData({
                seasonTicketEarningBySeller: seasonTicketEarningBySellerResponse.data,
                totalEarnedByDates: totalEarnedByDatesResponse.data,
                endUsersOfAccount: endUsersOfAccountResponse.data,
                eventOrdersOfAccount: eventOrdersOfAccountResponse.data,
                eventsOfAccount: eventsOfAccountResponse.data,
                totalUsersFromThisYear: totalUsersFromThisYearResponse.data,
                totalEarnedBySellers: totalEarnedBySellersResponse.data,
                earningByEvents: seasonTicketEarningBySellerGroupByEventResponse.data,
            })
        } catch (e) {
            console.error(e);
        }
    }

    useEffect(() => {
        if (!generalAppData) {
            fetchData()
        }
    }, [])

    return (
        generalAppData ? children : <LinearLoader />
    )
}
