import { LinearLoader } from "../../../../components/linear-loader/LinearLoader";
import { useEffect } from "react";
import { useSnackbar } from "notistack";
import { useMarketplaceContext } from "../../../../context-state/MarketplaceContext";
import { useSmsCampaignContext } from "../../../../context-state/SmsCampaignContext.jsx";
import { useAuthContext } from "../../../../context-state/AuthContext.jsx";
import * as API from "../../../../api/index.js";
import { useEndUsersContext } from "../../../../context-state/EndUsersContext.jsx";
import { getSmsCampaignBySellerId } from "../../../../api/index.js";

export const SmsCampaignGuard = ({ children }) => {

    const { account, getPrimaryAccount } = useAuthContext()
    const { smsCampaignContext } = useSmsCampaignContext()
    const { updateManySegments } = useEndUsersContext()
    const { marketplace } =  useMarketplaceContext()
    const { smsCampaignList, setSmsCampaignList } = smsCampaignContext;
    const { enqueueSnackbar } = useSnackbar()
    const sellerAccount = getPrimaryAccount()

    const fetchData = async () => {
        try {

            const smsCampaign = []
            // fetch all campaign - first by account, and if not account, get by marketPlaceId
            // if (account && account._id) {
            //     const smsCampaignFromAccount = await API.getSmsCampaignByAccountId({ accountId: account._id })
            //     if (smsCampaignFromAccount.data.length) {
            //         smsCampaign.push(...smsCampaignFromAccount.data)
            //     }
            // }

            // const smsCampaignFromMarketPlace = await API.getSmsCampaignByMarketplaceId({ marketplaceId: marketplace._id })
            // smsCampaign.push(...smsCampaignFromMarketPlace.data)

            if (sellerAccount) {
                const smsCampaignFromSeller = await API.getSmsCampaignBySellerId({ sellerId: sellerAccount._id })
                smsCampaign.push(...smsCampaignFromSeller.data)
                const { data } = await API.getSegmentsOfAccount({ accountId: sellerAccount._id, marketplaceId: sellerAccount.marketPlace._id })
                updateManySegments(data)
            }

            setSmsCampaignList(smsCampaign)

        } catch (e) {
            console.error(e);
            enqueueSnackbar(e.message, { variant: 'error' });
        }
    };

    useEffect(() => {
        if (!smsCampaignList) {
            fetchData()
        }
    }, [])



    return (
        smsCampaignList ? children : <LinearLoader />
    )
}
