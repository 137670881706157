import { S3Handler } from "../utils/s3";
import {
    createEvent,
    createEventTicket,
    getAccountIdByHandler,
    getEventByHandler,
    getEventOrdersOfEvent,
    fetchEventTickets,
    getUserEventsOfEvent,
    updateEvent,
    updateEventTicket,
    getEventCoupons,
    getUtmsOfEvent,
    fetchEventTicketsSecure,
    getLinksOfEventsSecured,
    getLinksOfEventsByUtm
} from "../api";
import { isDateGreaterThanToday, isDateLessThanToday } from "../utils/minimal/formatTime";
import * as API from "../api";
import { FeeCollectionTypes } from "../consts/index.js";
import { ErrorService } from "./errorService.js";

export const EventService = {
    async handleUpdateEventFlow(accountId, eventId, eventData, eventOriginalDesign) {

        const { event_main_photo, event_cover_photo, name, location, contact_email, event_handler, facebook_page, event_start_date, start_hour, end_hour, event_end_date, content, tickets, isSeatsEvent, feeCollectionType, is_manual_approve_buyer } = eventData

        if (event_main_photo?.shouldRenew && !event_main_photo.finalPath) {
            event_main_photo.finalPath = await S3Handler.uploadFile(event_main_photo)
        }

        if (event_cover_photo?.shouldRenew && !event_cover_photo.finalPath) {
            event_cover_photo.finalPath = await S3Handler.uploadFile(event_cover_photo)
        }

        const design = { image: event_main_photo.finalPath || event_main_photo.preview }
        const { colors } = getDesignColorsFromEventData(eventData)
        design.colors = colors

        design.imageWidth = event_main_photo.imageWidth ? event_main_photo.imageWidth : eventOriginalDesign.imageWidth
        design.imageHeight = event_main_photo.imageHeight ? event_main_photo.imageHeight : eventOriginalDesign.imageHeight

        if (event_cover_photo) {
            design.background = event_cover_photo.finalPath || event_cover_photo.preview
        }

        const eventDataToSend = {
            location,
            facebook_page,
            contact_email,
            design,
            name,
            start_hour,
            end_hour,
            isSeatsEvent,
            feeCollectionType,
            is_manual_approve_buyer,
            handler: event_handler,
            description: content,
            start_date: event_start_date,
            end_date: event_end_date,
            type: "electronic-party",  // TODO?
        }

        const updatedEventResponse = await updateEvent( { accountId, eventId, eventData: eventDataToSend })
        let allTicketsResponse = []
        if (!isSeatsEvent) {
            const ticketsPromises = tickets.map((ticket) => {
                if (ticket.ticketId) {
                    const {ticketId} = ticket
                    delete ticket.ticketId
                    const ticketToUpdate = {
                        name: ticket.name,
                        quantity: ticket.quantity,
                        price: ticket.price,
                        countTicketsAllowPerPurchase: ticket.countTicketsAllowPerPurchase,
                        is_enabled: ticket.is_active,
                        start_date: ticket.active_from_date,
                        end_date: ticket.inactive_from_date,
                        order: ticket.order,
                    }
                    return updateEventTicket({ accountId, eventId, ticketId, ticketData: ticketToUpdate }).then(({ data }) => data)
                }
                const ticketDataToCreate = {
                    name: ticket.name,
                    quantity: ticket.quantity,
                    price: ticket.price,
                    is_enabled: ticket.is_active,
                    start_date: ticket.active_from_date,
                    end_date: ticket.inactive_from_date,
                    countTicketsAllowPerPurchase: ticket.countTicketsAllowPerPurchase,
                    description: "_",
                    currency: "ILS",
                    category: "party",
                    is_sold_out: false,
                    is_deleted: false,
                    is_hidden: false,
                    is_free: false,
                    is_recurring: false
                }
                return createEventTicket({ accountId, eventId, ticketData: ticketDataToCreate }).then(({ data }) => data)
            })
            allTicketsResponse = await Promise.all(ticketsPromises)
        }


        return {
            event: updatedEventResponse.data,
            tickets: allTicketsResponse
        }

    },
    async handleCreateEventFlow(accountId, sellerId, eventData) {

        const { event_main_photo, event_cover_photo, name, location, contact_email, event_handler, facebook_page, event_start_date, start_hour, end_hour, event_end_date, content, tickets, isSeatsEvent, isAllowSeasonTicket, is_manual_approve_buyer, feeCollectionType } = eventData

        if (!event_main_photo.finalPath) {
            event_main_photo.finalPath = await S3Handler.uploadFile(event_main_photo)
        }

        if (event_cover_photo && !event_cover_photo.finalPath) {
            event_cover_photo.finalPath = await S3Handler.uploadFile(event_cover_photo)
        }

        const { eventBackground, eventButtons, eventProgressBar, eventPrivacyPolicy } = eventData
        const design = {image: event_main_photo.finalPath,}

        const { colors } = getDesignColorsFromEventData(eventData)

        design.colors = colors

        if (event_main_photo.imageWidth) {
            design.imageWidth = event_main_photo.imageWidth
        }

        if (event_main_photo.imageHeight) {
            design.imageHeight = event_main_photo.imageHeight
        }

        if (event_cover_photo) {
            design.background = event_cover_photo.finalPath
        }

        const eventDataToSend = {
            name,
            location,
            facebook_page,
            contact_email,
            design,
            start_hour,
            end_hour,
            isSeatsEvent,
            handler: event_handler,
            description: content,
            start_date: event_start_date,
            end_date: event_end_date,
            is_password_enabled: false,
            is_enabled: true,
            type: "electronic-party",  // TODO?
            seller: sellerId,
            isAllowSeasonTicket,
            is_manual_approve_buyer,
            feeCollectionType
        }

        const newEventResponse = await createEvent( { accountId, eventData: eventDataToSend })

        let allTicketsResponse = []
        if (!isSeatsEvent) {
            const promises = tickets.map(ticket => {
                const ticketData = {
                    name: ticket.name,
                    quantity: ticket.quantity,
                    price: ticket.price,
                    is_enabled: ticket.is_active,
                    start_date: ticket.active_from_date,
                    end_date: ticket.inactive_from_date,
                    countTicketsAllowPerPurchase: ticket.countTicketsAllowPerPurchase,
                    order: ticket.order,
                    description: "_",
                    currency: "ILS",
                    category: "party",
                    is_sold_out: false,
                    is_deleted: false,
                    is_hidden: false,
                    is_free: false,
                    is_recurring: false
                }
                return createEventTicket({ accountId, eventId: newEventResponse.data._id, ticketData })
            })
            allTicketsResponse = await Promise.all(promises)
        }

        return {
            event: newEventResponse.data,
            tickets: allTicketsResponse.map(({ data }) => data)
        }
    },
    async handleDuplicateEventFlow(accountId, eventToDuplicate, duplicateEventHandlerName) {

        const newEvent = {
            ...eventToDuplicate,
            name: `${eventToDuplicate.name} העתק`,
            handler: duplicateEventHandlerName,
            collaborates: [],
            links : []
        }

        delete newEvent._id
        delete newEvent.updatedAt
        delete newEvent.createdAt
        delete newEvent.seatsEventKey
        delete newEvent.seatsChartKey

        const eventTicketsToDuplicate = await fetchEventTickets({ accountId, eventId: eventToDuplicate._id })

        const createdEventResponse = await createEvent( { accountId, eventData: newEvent })

        const promises = eventTicketsToDuplicate.data.map(ticket => {
            delete ticket._id
            ticket.event = newEvent._id
            return createEventTicket({ accountId, eventId: createdEventResponse.data._id, ticketData: ticket })
        })

        await Promise.all(promises)
        return createdEventResponse.data
    },
    async getEventPageData(accountHandler, eventHandler, utm) {

        const accountResponse = await getAccountIdByHandler(accountHandler)
        const accountId = accountResponse.data._id
        const eventResponse = await getEventByHandler({ accountId, eventHandler, utm })
        const eventTickets = await fetchEventTickets({ accountId, eventId: eventResponse.data._id, nimiFeeType: eventResponse.data.feeCollectionType === FeeCollectionTypes.ON_TOP_OF_TICKET ? FeeCollectionTypes.ON_TOP_OF_TICKET : null })

        let relevantLink
        if (utm) {
            try {
                const eventLinkResponse = await getLinksOfEventsByUtm({ accountId, eventId: eventResponse.data._id, utm })
                relevantLink = eventLinkResponse.data
            } catch (e) {
                ErrorService.handleError(e)
                console.log(e);
            }
        }

        return {
            eventData: eventResponse.data,
            accountData: accountResponse.data,
            ticketsData: eventTickets.data.filter(ticket => {

                const { is_enabled, start_date, end_date, _id } = ticket
                // ticket.countTicketsAllowPerPurchase = Math.min(ticket.countTicketsAllowPerPurchase, ticket.ticketsLeft) // TODO - check this after dan will fix it
                // If ticket assign to utm, it will override any other check.
                // Also, if is_sold_out is true, we return an empty
                if (relevantLink && relevantLink.is_enabled) {
                    if (relevantLink.is_sold_out) {
                        return false
                    }
                    if (relevantLink.countTicketsAllowPerPurchase) {
                        ticket.countTicketsAllowPerPurchase = Math.min(ticket.countTicketsAllowPerPurchase, relevantLink.countTicketsAllowPerPurchase)
                    }
                    return relevantLink.tickets.includes(_id)
                }

                if (!is_enabled || is_enabled === 'false') {
                    return false
                }

                if (start_date && isDateGreaterThanToday(start_date)) {
                    return false
                }

                if (end_date && isDateLessThanToday(end_date)) {
                    return false
                }

                return true
            }),
            marketplaceData: accountResponse.data.marketPlace
        }

    },
    async getEventDashboardData({ accountId, eventId }) {

        const eventData = await Promise.all([
            getUserEventsOfEvent({ accountId, eventId }).then(({data}) => data ),
            getEventOrdersOfEvent({ accountId, eventId }).then(({data}) => data ),
            fetchEventTicketsSecure({ accountId, eventId }).then(({data}) => data ),
            getEventCoupons({ accountId, eventId }).then(({data}) => data ),
            getUtmsOfEvent({ accountId, eventId }).then(({data}) => data ),
            getLinksOfEventsSecured({ accountId, eventId }).then(({data}) => data ),
        ])

        const userEvents = eventData[0]
        const eventOrders = eventData[1]
        const tickets = eventData[2]
        const coupons = eventData[3]
        const utms = eventData[4]
        const links = eventData[5]

        return { userEvents, eventOrders, tickets, coupons, utms, links }

    },
    async updateOrCreateNimiTicketForSeatsIoTicket({ ticket, seatsIoTicketCategory, accountId, eventId, ticketQuantity }){
        if (ticket._id) {
            const ticketToUpdate = {
                name: seatsIoTicketCategory.label,
                price: ticket.price,
                quantity: ticketQuantity,
                seatsCategoryKey: seatsIoTicketCategory.key,
                seatsCategoryLabel: seatsIoTicketCategory.label,
                seatsCategoryColor: seatsIoTicketCategory.color,
            }
            return API.updateEventTicket({ accountId, eventId, ticketId: ticket._id, ticketData: ticketToUpdate })
        }

        const ticketDataToCreate = {
            name: seatsIoTicketCategory.label,
            price: ticket.price,
            seatsCategoryKey: seatsIoTicketCategory.key,
            seatsCategoryLabel: seatsIoTicketCategory.label,
            seatsCategoryColor: seatsIoTicketCategory.color,
            quantity: ticketQuantity,
            is_enabled: true,
            start_date: null,
            end_date: null,
            countTicketsAllowPerPurchase: ticket.countTicketsAllowPerPurchase,
            description: "Nimi representation for Seats.io tickets",
            currency: "ILS",
            category: "party",
            is_sold_out: false,
            is_deleted: false,
            is_hidden: false,
            is_free: false,
            is_recurring: false
        }
        return API.createEventTicket({ accountId, eventId, ticketData: ticketDataToCreate })

    }
}

const getDesignColorsFromEventData = (eventData) => {
    const { eventBackground, eventButtons, eventProgressBar, eventPrivacyPolicy } = eventData
    return { 
        colors: {
            background: eventBackground,
            buttons: eventButtons,
            progressBar: eventProgressBar,
            privacyPolicy: eventPrivacyPolicy
        }
    }
}

const mapDesignColorsToEventFrom = (design) => {
    const { colors = {} } = design
    const { background, buttons, progressBar, privacyPolicy } = colors
    return {
        eventBackground: background,
        eventButtons: buttons,
        eventProgressBar: progressBar,
        eventPrivacyPolicy: privacyPolicy
    }
}