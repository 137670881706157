import { useLocales } from "../../../locales";
import { Button, CircularProgress, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useMarketplaceContext } from "../../../context-state/MarketplaceContext.jsx";
import { m } from "framer-motion";
import { sendSms } from "../../../api/index.js";
import { useSnackbar } from "../../../components/minimal/snackbar/index.jsx";
import { useAuthContext } from "../../../context-state/AuthContext.jsx";

export const SmsCampaignSummery = ({onSuccess, smsCampaignContent, smsCampaignPhoneNumbers}) => {
    const { marketplace } = useMarketplaceContext()
    const { enqueueSnackbar } = useSnackbar()
    const { getPrimaryAccount } =  useAuthContext()
    const { translate } = useLocales()
    const [isLoading, setIsLoading] = useState(false)
    const sellerAccount = getPrimaryAccount()

    const handleOnClick = async () => {
        try {
            setIsLoading(true)
            const smsData = {
                phone_numbers: smsCampaignPhoneNumbers,
                content: smsCampaignContent.content,
                name: smsCampaignContent.name
            }
            const res = await sendSms({ marketplaceId: marketplace._id, smsData, sellerId: sellerAccount?._id })
            enqueueSnackbar('אסמסים נשלחו בהצלחה!')
            onSuccess(res.data);
        } catch (e) {
            enqueueSnackbar(e.message, { variant: 'error' })
            console.error(e)
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <m.div
            initial={{opacity: 0, y: 20}} // Initial state
            animate={{opacity: 1, y: 0}}   // Animation state
            exit={{opacity: 0, y: -20}}    // Exit state
            transition={{duration: 0.3}}   // Transition duration
        >
            {isLoading ? (
                <Stack sx={{justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                    <CircularProgress/>
                </Stack>
            ) : (
                <>
                    <Typography sx={{display: 'flex', justifyContent: 'right'}} variant="subtitle1" paragraph>
                        <Button type="submit" variant="contained" onClick={handleOnClick}>
                            שלח!
                        </Button>
                    </Typography>
                    <Typography variant="subtitle1" paragraph>
                        {translate('dashboard.sms_campaign.name')}
                    </Typography>
                    <Typography sx={{display: "flex", justifyContent: 'center'}} variant="subtitle2" paragraph>
                        {smsCampaignContent.name}
                    </Typography>
                    <Typography variant="subtitle1" paragraph>
                        {translate('dashboard.sms_campaign.content')}
                    </Typography>
                    <Typography sx={{display: "flex", justifyContent: 'center'}} variant="subtitle2" paragraph>
                        {smsCampaignContent.content}
                    </Typography>
                    <Typography variant="subtitle1" paragraph>
                        <span style={{ marginLeft: "4px" }}>
                            {translate('dashboard.sms_campaign.phones')}
                        </span>
                        (סך הכל - {smsCampaignPhoneNumbers.length})
                    </Typography>
                    <Stack sx={{ overflow: "scroll", maxHeight: "200px" }}>
                        {smsCampaignPhoneNumbers.map((phone, index) => (
                            <Stack key={index} sx={{ display: "flex", justifyContent: 'center', alignItems: "center", flexDirection: "row" }}>
                                <Stack sx={{ fontWeight: "bold", mr: 1 }}>{index + 1}.</Stack>
                                <Typography>
                                    {phone}
                                </Typography>
                            </Stack>
                        ))}
                    </Stack>
                </>
            )}
        </m.div>
    );
}
