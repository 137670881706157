import { useLocation, matchPath } from 'react-router-dom';

// ----------------------------------------------------------------------

export default function useActiveLink(path, endsWith = false, deep = true, simple = true) {
  const { pathname } = useLocation();

  const normalActive = path ? !!matchPath({ path, end: true }, pathname) : false;

  const deepActive = path ? !!matchPath({ path, end: false }, pathname) : false;

  let isActive = deep ? deepActive : normalActive

  if (simple) {
    isActive = pathname.includes(path)
  }

  if (endsWith) {
    isActive = pathname.endsWith(path)
  }

  return {
    active: isActive,
    isExternalLink: path.includes('http'),
  };
}
