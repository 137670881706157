import * as API from "../../../../api";
import { LinearLoader } from "../../../../components/linear-loader/LinearLoader";
import { useSnackbar } from "../../../../components/minimal/snackbar";
import { useEffect } from "react";
import { useAuthContext } from "../../../../context-state/AuthContext.jsx";
import { useNavigate } from "react-router-dom";
import { useEndUsersContext } from "../../../../context-state/EndUsersContext.jsx";
import { getAccountEvents, getSubAccountsByParentId } from "../../../../api";

export const EndUsersGuard = ({ children }) => {

    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar();
    const { getPrimaryAccount } = useAuthContext()
    const sellerAccount = getPrimaryAccount()
    const { getEndUsersOfSellerAccount, updateManyEndUsers, updateSellerAccountToEndUsersRelation, setPaginationData, updateManySegments, updateSellerAccountToSegmentRelation, updateSegmentToEndUsersRelation } = useEndUsersContext()
    const endUsersLoaded = getEndUsersOfSellerAccount(sellerAccount?._id)

    const getEndUserData = async () => {
        try {

            if (!sellerAccount) {
                navigate('/dashboard')
                enqueueSnackbar('ישנה בעיה עם פרטי הלקוחות, אנא צרו קשר עם מנהל המערכת', { variant: 'error' })
                return
            }

            const [endUsersResponse, segmentsResponse ] = await Promise.all([
                API.getEndUsersOfAccount({ accountId: sellerAccount._id, marketplaceId: sellerAccount.marketPlace._id }),
                API.getSegmentsOfAccount({ accountId: sellerAccount._id, marketplaceId: sellerAccount.marketPlace._id })
            ])

            const endUsersOfSegmentsResponses = segmentsResponse.data.map(segment => {
                return API.getEndUsersOfSegment({ accountId: sellerAccount._id, marketplaceId: sellerAccount.marketPlace._id, segmentId: segment._id }).then(res => ({ segmentId: segment._id, endUsers: res.data }))
            })

            const endUsersOfSegments = await Promise.all(endUsersOfSegmentsResponses)

            updateManyEndUsers(endUsersResponse.data.data)
            updateSellerAccountToEndUsersRelation(sellerAccount._id, endUsersResponse.data.data)

            updateManySegments(segmentsResponse.data)
            updateSellerAccountToSegmentRelation(sellerAccount._id, segmentsResponse.data)
            updateSegmentToEndUsersRelation(endUsersOfSegments)

            const { limit, count } = endUsersResponse.data.pagination
            endUsersResponse.data.pagination.hasMore = limit < count
            setPaginationData(endUsersResponse.data.pagination) // {limit: 10, page: 1, count: 95} (count is the total number of end users in the account)

        } catch (e) {
            enqueueSnackbar(e.message, { variant: 'error' })
            console.error(e);
        }
    }

    useEffect(() => {
        if (!endUsersLoaded) {
            getEndUserData()
        }
    }, [])

    return (
        endUsersLoaded ? children : <LinearLoader />
    )
}
