// routes
import { PATH_AUTH } from '../../../routes/paths';
// components
import Iconify from '../../../components/minimal/iconify';

// ----------------------------------------------------------------------

const navConfig = [
  {
    title: 'Home',
    icon: <Iconify icon="eva:home-fill" />,
    path: '/',
  },
  {
    title: 'Login',
    path: PATH_AUTH.login,
    icon: <Iconify icon="eva:log-in-outline" />,
  },
];

export default navConfig;
