import CustomBreadcrumbs from "../../components/minimal/custom-breadcrumbs";
import { fDate } from "../../utils/minimal/formatTime";
import { Stack } from "@mui/material";
import Label from "../../components/minimal/label";
import { useLocales } from "../../locales";
import useCopyToClipboard from "../../hooks/minimal/useCopyToClipboard";
import { useSnackbar } from "notistack";
import { useEventLink } from "../../hooks/useEventLink";

export const EventBreadcrumbs = ({ heading, event, action, sx = {} }) => {

    const { translate } = useLocales()
    const { enqueueSnackbar } = useSnackbar();
    const { copy } = useCopyToClipboard();
    const eventUrl = useEventLink()

    const handleCopy = () => {
        enqueueSnackbar(translate('dashboard.events.copied'));
        copy(eventUrl);
    };

    return (
        <Stack>
            <CustomBreadcrumbs
                action={action}
                sx={sx}
                heading={heading}
                headAction={
                    <Label onClick={handleCopy} sx={{ py: 1, mt: 1, cursor: "pointer" }} color="info" variant="soft">
                        העתק לינק אירוע
                    </Label>
                }
                links={[
                    {
                        name: event.name,
                    },
                    {
                        name: fDate(event.start_date),
                    }
                ]}
            />
        </Stack>

    )
}